import { template as template_7d27a3d04f234759b3a87f0fdae7bc26 } from "@ember/template-compiler";
const WelcomeHeader = template_7d27a3d04f234759b3a87f0fdae7bc26(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
