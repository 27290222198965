import { template as template_0f24a46eb43e4cf5b398d5b2aa3673f5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0f24a46eb43e4cf5b398d5b2aa3673f5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
