import { template as template_9a0b7f06a96944b3bc10907e5a8d7f71 } from "@ember/template-compiler";
const FKText = template_9a0b7f06a96944b3bc10907e5a8d7f71(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
